import {createContext} from "react";
import {User} from "../models/User";
import {GameType} from "../models/GameType/GameType";
import {FriendRequest} from "../models/FriendRequest";

export type AuthContextType = {
    user?: User;
    setUser: (user?: User) => void;
    getUser: () => void;

    friendRequests: FriendRequest[];
    getFriendRequests: () => void;

    gameTypes: GameType[];
    getGameTypes: () => void;
}

const initialState: AuthContextType = {
    user: new User(),
    setUser: (user?: User) => {},
    getUser: async () => {},
    friendRequests: [],
    getFriendRequests: async () => {},
    gameTypes: [],
    getGameTypes: async () => {}
}

export const AuthContext = createContext(initialState);
