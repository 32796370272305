import {BaseEntity} from "./BaseEntity";
import {Scoreboard} from "./Scoreboard";

export class User extends BaseEntity{
    externalId!: string;

    email!: string;

    userHandle!: string;

    firstName!: string;

    lastName!: string;

    age?: number;

    displayName?: string;

    profilePicUrl?: string;

    friends!: string[];

    scoreboards?: Scoreboard[];
}
