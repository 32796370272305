import React, {Fragment, useContext, useEffect, useState} from 'react';
import useApiClient from "../../hooks/use-api-client";

import styles from "../Shared.module.scss";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {Backdrop, Box, Modal} from "@mui/material";
import CreateGameTypeForm from "../../components/Forms/CreateGameTypeForm/CreateGameTypeForm";
import {AuthContext} from "../../hooks/use-auth-state";
import {Loading} from "../../components/UI/Loading/Loading";
import {FriendRequest} from "../../models/FriendRequest";
import SendFriendRequestForm from "../../components/Forms/SendFriendRequestForm/SendFriendRequestForm";
import {Spacer} from "../../components/UI/Spacer/Spacer";
import {LineSeparator} from "../../components/UI/LineSeparator/LineSeparator";
import {classNames} from "../../utils/jsx-utils";
import {CloseBar} from "../../components/UI/CloseBar/CloseBar";

const smallButtonStyle = {
    padding: '4px',
    minWidth: "32px",
}

const Settings = () => {
    const {sendPostRequest, sendGetRequest, sendDeleteRequest} = useApiClient();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFriendRequestModalOpen, setIsFriendRequestModalOpen] = useState(false);
    const [removeFriendHandle, setRemoveFriendHandle] = useState<string>('');
    const [sentFriendRequests, setSentFriendRequests] = useState<FriendRequest[]>([]);

    const {user, getGameTypes, getUser, friendRequests, getFriendRequests} = useContext(AuthContext);

    useEffect(() => {
        if (user) {
            refreshFriendRequests();
        }
    }, [user]);

    const refreshFriendRequests = async () => {
        await getFriendRequests();
        const sent = await sendGetRequest("/api/users/friend-requests/sent");
        if (sent.ok) {
            setSentFriendRequests(sent.body as FriendRequest[]);
        }
    }

    const sendRemoveFriendRequest = async () => {
        if (!removeFriendHandle) return;
        await sendPostRequest("/api/users/friends/remove", {userHandle: removeFriendHandle});
        getUser();
    }

    const seedData = async () => {
        await sendPostRequest('/api/users/seed');
    }

    const onGameTypeCreated = async () => {
        await getGameTypes();
        setIsModalOpen(false);
    }

    const onFriendRequestSent = async () => {
        setIsFriendRequestModalOpen(false);
        refreshFriendRequests();
    }

    if (!user) {
        return <Loading />
    }

    const getImage = () => {
        if (user.profilePicUrl) {
            return <img
                alt={user.userHandle.substring(0,2).toUpperCase()}
                src={user.profilePicUrl}
                height={50}
                width={50}
                style={{borderRadius: "50%"}}
            />
        } else {
            return <div>${user.userHandle.substring(0,2).toUpperCase()}</div>
        }
    }

    const confirmFriendRequest = async (id?: string | number) => {
        if (!id) return;

        await sendPostRequest("/api/users/friend-requests/confirm", {friendRequestId: `${id}`});

        refreshFriendRequests();
        getUser();
    }

    const denyFriendRequest = async (id?: string | number) => {
        if (!id) return;

        await sendDeleteRequest("/api/users/friend-requests/cancel", {friendRequestId: `${id}`});

        refreshFriendRequests();
        getUser();
    }

    const getFriendRequestRows = () => {
        return friendRequests.map(it => {
            return (
                <Fragment key={`friend-request-received-${it.id}`}>
                    <Grid item xs={12}>
                        {it.requestingUser?.userHandle}&nbsp;&nbsp;&nbsp;
                        <Button
                            style={{
                                ...smallButtonStyle,
                                marginLeft: '12px'
                            }}
                            variant={'outlined'}
                            onClick={() => confirmFriendRequest(it.id)}
                            color={"success"}>
                            Confirm
                        </Button>&nbsp;&nbsp;
                        <Button
                            style={{
                                ...smallButtonStyle,
                                marginLeft: '12px'
                            }}
                            variant={'outlined'}
                            onClick={() => denyFriendRequest(it.id)}
                            color={"warning"}>
                            Deny
                        </Button>
                    </Grid>
                </Fragment>
            )
        })
    }

    const getSentFriendRequestRows = () => {
        return sentFriendRequests.map(it => {
            return (
                <Fragment key={`friend-request-received-${it.id}`}>
                    <Grid item xs={12}>
                        {it.requestedUser?.userHandle}
                    </Grid>
                </Fragment>
            )
        })
    }

    const getFriendsList = () => {
        const getEnd = (name: string) => {
            if (name === user.userHandle) {
                return "(you)"
            } else {
                return (
                    <div className={styles["align-right"]}>
                        <Button
                            style={smallButtonStyle}
                            onClick={() => setRemoveFriendHandle(name)}
                            color={"warning"}
                            variant={'outlined'}
                        >
                            X
                        </Button>
                    </div>
                )
            }
        }

        return user.friends?.map(it => {
            return (
                <Fragment key={`friend-list-${it}`}>
                    <Grid item xs={12} md={4}>
                        {it} {getEnd(it)}
                    </Grid>
                    <Grid item xs={0} md={8} />
                </Fragment>
            )
        })
    }

    return (
        <>
            <Container fixed className={styles.container}>
                <Grid container spacing={2}>
                    <Grid item xs={3} md={1}>
                        {getImage()}
                    </Grid>
                    <Grid item xs={9} md={11}>
                        {user.userHandle}
                    </Grid>

                    <Spacer space={40} />

                    <Grid item xs={4} />
                    <Grid className={styles.center} justifyContent={'center'} item xs={4}>
                        <span className={styles["shared-normal-font-big"]}>
                            Friends
                        </span>
                    </Grid>
                    <Grid item xs={4} />

                    {getFriendsList()}

                    {
                        !!friendRequests.length && (
                            <>
                                <Spacer space={40} />
                                <LineSeparator />
                                <Grid item md={4} xs={0} />
                                <Grid className={styles.center} justifyContent={'center'} item md={4} xs={12}>
                                    <span className={styles["shared-normal-font-big"]}>
                                        Friend Requests
                                    </span>
                                </Grid>
                                <Grid item md={4} xs={0} />

                                {getFriendRequestRows()}
                            </>
                        )
                    }
                    {
                        !!sentFriendRequests.length && (
                            <>
                                <Spacer space={40} />
                                <LineSeparator />
                                <Grid item md={4} xs={0} />
                                <Grid className={styles.center} justifyContent={'center'} item md={4} xs={12}>
                                    <span className={styles["shared-normal-font-big"]}>
                                        Sent Friend Requests
                                    </span>
                                </Grid>
                                <Grid item md={4} xs={0} />

                                {getSentFriendRequestRows()}
                            </>
                        )
                    }
                    <Spacer space={48} />
                    <Grid className={styles.center} justifyContent={'center'} item md={4} xs={12}>
                        <Button
                            onClick={() => setIsFriendRequestModalOpen(true)}
                            variant={"contained"}
                            color={"success"}>
                            Add Friend
                        </Button>
                    </Grid>
                    <Grid className={styles.center} justifyContent={'center'} item md={4} xs={12}>
                        <Button
                            onClick={() => setIsModalOpen(true)}
                            variant={"contained"}
                            color={"success"}>
                            Add Game Type
                        </Button>
                    </Grid>
                    <Grid className={styles.center} justifyContent={'center'} item md={4} xs={12}>
                        {process.env.REACT_APP_API_BASE?.includes("localhost") && (
                            <Button variant={"contained"} onClick={seedData}>
                                Seed data
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Container>
            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Box className={styles.formContainer}>
                    <CloseBar onClick={() => setIsModalOpen(false)} />
                    <CreateGameTypeForm onSuccess={onGameTypeCreated} />
                </Box>
            </Modal>
            <Modal
                open={isFriendRequestModalOpen}
                onClose={() => setIsFriendRequestModalOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Box className={styles.formContainer}>
                    <CloseBar onClick={() => setIsFriendRequestModalOpen(false)} />
                    <SendFriendRequestForm onSuccess={onFriendRequestSent} />
                </Box>
            </Modal>
            <Modal
                open={!!removeFriendHandle}
                onClose={() => setRemoveFriendHandle('')}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Box className={styles.formContainer}>
                    <Grid item xs={4} />
                    <Grid className={classNames(styles.center, styles["shared-normal-font-big"])} justifyContent={'center'} item xs={4}>
                        Are you sure you want to delete this user from your friends list?
                    </Grid>
                    <Grid item xs={4} />

                    <Spacer space={40} />

                    <Grid item xs={4} />
                    <Grid className={styles.center} justifyContent={'center'} item xs={4}>
                        <Button
                            onClick={sendRemoveFriendRequest}
                            color={"success"}
                            variant={'contained'}
                        >
                            Confirm
                        </Button>
                    </Grid>
                    <Grid item xs={4} />
                </Box>
            </Modal>
        </>
    );
}

export default Settings;
