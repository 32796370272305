import React, {Fragment, useState} from 'react';

import TextField from '@mui/material/TextField';
import {Box, FormControl} from "@mui/material";
import {Spacer} from "../../UI/Spacer/Spacer";
import Button from "@mui/material/Button";
import useApiClient from "../../../hooks/use-api-client";
import {Loading} from "../../UI/Loading/Loading";
import {Game} from "../../../models/Game";
import {Scoreboard} from "../../../models/Scoreboard";

type AddGameToScoreboardFormProps = {
    onSuccess: (game: Game) => void;
    scoreboard: Scoreboard;
}

type ScoreSubmitType = {
    userHandle: string;
    score: number;
}

const AddGameToScoreboardForm = ({onSuccess, scoreboard}: AddGameToScoreboardFormProps) => {
    const {sendPostRequest} = useApiClient();
    const [scores, setScores] = useState<ScoreSubmitType[]>([]);
    const [loading, setLoading] = useState(false);

    const submit = async () => {
        setLoading(true);
        const res = await sendPostRequest(`/api/scoreboards/${scoreboard.id}/game`, {scores});
        setLoading(false);

        if (res.ok) {
            onSuccess(res.body as Game);
        }
    }

    const userScore = (user: string) => {
        const s = scores?.find(it => it.userHandle === user)?.score;
        if (s !== undefined) {
            return s
        } else {
            return ''
        }
    }

    const setScore = (user: string, userScore: string) => {
        const score = scores?.find(it => it.userHandle === user);
        if (score === undefined) {
            scores.push({
                userHandle: user,
                score: parseInt(userScore)
            })
        } else {
            score.score = parseInt(userScore)
        }

        setScores([...scores]);
    }

    const getInputs = () => {
        return (
            scoreboard.users?.map((user) => {
                return (
                    <Fragment key={`scoreboard-score-${user.userHandle}`}>
                        <FormControl fullWidth>
                            <TextField
                                required
                                id="outlined-required"
                                autoComplete={'off'}
                                label={`${user.userHandle} score`}
                                value={userScore(user.userHandle)}
                                type={'number'}
                                onChange={(e) => setScore(user.userHandle, e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>
                        <Spacer space={14} />
                    </Fragment>
                )
            })
        )
    }

    return (
        <Box component="form">
            <div>
                {getInputs()}
                <FormControl fullWidth>
                    {
                        loading ? <Loading /> : (
                            <Button
                                onClick={submit}
                                variant={"contained"}
                                color={"success"}
                            >
                                Submit
                            </Button>
                        )
                    }
                </FormControl>
            </div>
        </Box>
    );
}

export default AddGameToScoreboardForm;
