import React, {useContext, useEffect, useState} from 'react';
import useApiClient from "../../hooks/use-api-client";

import styles from "../Shared.module.scss";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {FormControl} from "@mui/material";
import {AuthContext} from "../../hooks/use-auth-state";
import TextField from "@mui/material/TextField";
import {useNavigate} from "react-router-dom";

const SignUp = () => {
    const navigate = useNavigate();
    const {sendPostRequest} = useApiClient();
    const [userHandle, setUserHandle] = useState("");
    const [accessCode, setAccessCode] = useState("");
    const [error, setError] = useState('');

    const {user, getUser} = useContext(AuthContext);

    const registerUser = async () => {
        const res = await sendPostRequest('/api/users/register', {userHandle, earlyAccessCode: accessCode});
        if (res.ok) {
            setError('')
            await getUser();
        } else {
            setError('Something bad happened. Ask Jake to help.')
        }
    }

    useEffect(() => {
        if (user) {
            navigate("/");
        }
    }, [user])

    return (
        <>
            <Container fixed className={styles.container}>
                <Grid container spacing={2}>
                    <Grid item xs={4} />
                    <Grid item xs={4}>
                        Register for TopScore now. Just enter your user handle to get started!
                    </Grid>
                    <Grid item xs={4} />

                    <Grid item xs={4} />
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField
                                required
                                id="outlined-required"
                                label="Name"
                                autoComplete={'off'}
                                value={userHandle}
                                onChange={(e) => setUserHandle(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} />

                    <Grid item xs={4} />
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField
                                required
                                id="outlined-required"
                                label="Early Access Code"
                                autoComplete={'off'}
                                value={accessCode}
                                onChange={(e) => setAccessCode(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} />

                    <Grid item xs={4} />
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <Button variant={"contained"} onClick={registerUser}>
                                Register
                            </Button>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} />

                    {error && (
                        <>
                            <Grid item xs={4} />
                            <Grid style={{color: "red"}} item xs={4}>
                                {error}
                            </Grid>
                            <Grid item xs={4} />
                        </>
                    )}

                </Grid>
            </Container>
        </>
    );
}

export default SignUp;
