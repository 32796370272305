import React, {useContext, useState} from 'react';

import TextField from '@mui/material/TextField';
import {Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {Spacer} from "../../UI/Spacer/Spacer";
import Button from "@mui/material/Button";
import useApiClient from "../../../hooks/use-api-client";
import {GameType} from "../../../models/GameType/GameType";
import {Loading} from "../../UI/Loading/Loading";
import {AuthContext} from "../../../hooks/use-auth-state";
import {CreateScoreboard} from "../../../models/CreateScoreboard";

type CreateGameTypeFormProps = {
    onSuccess: (gameType: GameType) => void;
}

const CreateGameTypeForm = ({onSuccess}: CreateGameTypeFormProps) => {
    const {sendPostRequest} = useApiClient();
    const [name, setName] = useState("");
    const [selectedGameType, setSelectedGameType] = useState<GameType | "">("");
    const [selectedUsers, setSelectedUsers] = useState<string[]>([])
    const [loading, setLoading] = useState(false);

    const {gameTypes, user} = useContext(AuthContext);

    const submit = async () => {
        setLoading(true);
        const scoreboard: CreateScoreboard = {
            name,
            gameType: selectedGameType as GameType,
            users: selectedUsers
        }

        const res = await sendPostRequest("/api/scoreboards/", scoreboard);

        setLoading(false);

        if (res.ok) {
            onSuccess(res.body as GameType);
        }
    }

    const onUsersChanged = (event: SelectChangeEvent<typeof selectedUsers>) => {
        const {
            target: { value },
        } = event;
        setSelectedUsers(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <Box component="form">
            <div>
                <FormControl fullWidth>
                    <TextField
                        required
                        id="outlined-required"
                        label="Name"
                        autoComplete={'off'}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </FormControl>
                <Spacer space={14} />
                <FormControl fullWidth>
                    <InputLabel>Game</InputLabel>
                    <Select
                        value={selectedGameType}
                        label="Game Type"
                        onChange={(e) => setSelectedGameType(e.target.value as GameType)}
                    >
                        {
                            gameTypes.map(it => {
                                return (
                                    <MenuItem key={`game-type-${it.id}`} value={it.id}>{it.name}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
                <Spacer space={14} />
                <FormControl fullWidth>
                    <InputLabel>Competitors</InputLabel>
                    <Select
                        value={selectedUsers}
                        label="Users"
                        multiple
                        onChange={onUsersChanged}
                    >
                        {
                            user?.friends.map(it => {
                                return (
                                    <MenuItem key={`friends-${it}`} value={it}>{it}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
                <Spacer space={14} />
                <FormControl fullWidth>
                    {
                        loading ? <Loading /> : (
                            <Button
                                onClick={submit}
                                variant={"contained"}
                                color={"success"}
                            >
                                Submit
                            </Button>
                        )
                    }
                </FormControl>
            </div>
        </Box>
    );
}

export default CreateGameTypeForm;
