import React, {useContext, useState} from 'react';

import styles from "../Shared.module.scss";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {Backdrop, Box, Modal} from "@mui/material";
import {AuthContext} from "../../hooks/use-auth-state";
import CreateScoreboardForm from "../../components/Forms/CreateScoreboardForm/CreateScoreboardForm";
import CustomTable from "../../components/Table/Table";
import {Scoreboard} from "../../models/Scoreboard";
import {useNavigate} from "react-router-dom";
import {Spacer} from "../../components/UI/Spacer/Spacer";
import {CloseBar} from "../../components/UI/CloseBar/CloseBar";

const scoreboardColumns = [
    {
        key: "gameType.name",
        title: "Game Type"
    },
    {
        key: "name",
        title: "Name"
    }
]

const MyScoreboards = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const {user, getUser} = useContext(AuthContext);

    const onCreateScoreboardSuccess = async () => {
        setIsModalOpen(false);
        await getUser();
    }

    if (!user) {
        return (
            <div>
                Error
            </div>
        )
    }

    const onRowClick = (data: Scoreboard) => {
        navigate("/my-scoreboards/" + data.id);
    }

    const getScoreboardList = () => {
        if (!user.scoreboards?.length) {
            return (
                <>
                    <Grid item xs={0} md={4} />
                    <Grid className={styles.center} justifyContent={'center'} item xs={12} md={4}>
                        You have no scoreboards. Create one now!
                    </Grid>
                    <Grid item xs={0} md={4} />
                </>
            )
        }

        return (
            <Grid item xs={12}>
                <CustomTable onRowClick={onRowClick} rows={user.scoreboards || []} columns={scoreboardColumns} />
            </Grid>
        )
    }

    return (
        <>
            <Container fixed className={styles.container}>
                <Grid container spacing={2}>
                    <Grid item xs={0} md={4} />
                    <Grid className={styles.center} justifyContent={'center'} item xs={12} md={4}>
                        My Scoreboards
                    </Grid>
                    <Grid className={styles.center} justifyContent={'center'} item xs={12} md={4}>
                        <Button
                            onClick={() => setIsModalOpen(true)}
                            variant={"contained"}
                            color={"success"}>
                            Create a scoreboard!
                        </Button>
                    </Grid>

                    <Spacer space={20} />

                    {getScoreboardList()}
                </Grid>
            </Container>
            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Box className={styles.formContainer}>
                    <CloseBar onClick={() => setIsModalOpen(false)} />
                    <CreateScoreboardForm onSuccess={onCreateScoreboardSuccess} />
                </Box>
            </Modal>
        </>
    );
}

export default MyScoreboards;
