import {useAuth0} from "@auth0/auth0-react";

export type ApiResponse = {
    ok: boolean,
    body?: object,
    error?: any
}

const useApiClient = () => {
    const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

    const makeRequest = async (path: string, method: string, body?: object): Promise<ApiResponse> => {
        let token;
        try {
            token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE
            });
        } catch (err) {
            console.error("Token Error: ", err)
            token = await getAccessTokenWithPopup({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE
            });
        }

        const headers = {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json;charset=UTF-8"
        }
        const req: any = {
            headers,
            method: method
        }

        if (body) {
            req.body = JSON.stringify(body);
        }

        try {
            const res = await fetch(process.env.REACT_APP_API_BASE + path, req);
            const json = await res.json();
            if (res.status > 399) {
                return {
                    ok: false,
                    error: json
                }
            }
            return {
                ok: true,
                body: json
            }
        } catch (err) {
            return {
                ok: false,
                error: err
            }
        }
    }


    const get = async (path: string): Promise<ApiResponse> => {
        return makeRequest(path, "GET");
    }

    const post = async (path: string, body?: object): Promise<ApiResponse> => {
        return makeRequest(path, "POST", body);
    }

    const remove = async (path: string, body?: object): Promise<ApiResponse> => {
        return makeRequest(path, "DELETE", body);
    }

    return {
        sendPostRequest: post,
        sendGetRequest: get,
        sendDeleteRequest: remove
    }
}

export default useApiClient;
