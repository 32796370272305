import React, {Fragment, useContext, useEffect, useState} from 'react';

import styles from "../Shared.module.scss";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {Backdrop, Box, Modal} from "@mui/material";
import {AuthContext} from "../../hooks/use-auth-state";
import {Loading} from "../../components/UI/Loading/Loading";
import {useNavigate, useParams} from "react-router-dom";
import CustomTable from "../../components/Table/Table";
import {Scoreboard} from "../../models/Scoreboard";
import useApiClient from "../../hooks/use-api-client";
import AddGameToScoreboardForm from "../../components/Forms/AddGameToScoreboardForm/AddGameToScoreboardForm";
import {Score} from "../../models/Score";
import {Spacer} from "../../components/UI/Spacer/Spacer";
import {CloseBar} from "../../components/UI/CloseBar/CloseBar";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {ScoreType} from "../../models/GameType/CreateGameType";

const ScoreboardPage = () => {
    const navigate = useNavigate();
    const {sendGetRequest} = useApiClient();
    const {id = ''} = useParams();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [scoreboard, setScoreboard] = useState<Scoreboard | undefined>(undefined);
    const [rows, setRows] = useState<any[]>([])
    const [scoreboardStats, setScoreboardStats] = useState<any>({});
    const [champ, setChamp] = useState("");
    const [highScore, setHighScore] = useState<{[key: string]: any}>({});
    const [selectedGame, setSelectedGame] = useState<any>(undefined);
    const [currentStreak, setCurrentStreak] = useState(0);
    const [longestStreak, setLongestStreak] = useState(0);

    const isHighScoreType = () => {
        return scoreboard?.gameType?.scoreType !== ScoreType.LowerScoreWins
    }

    const sortedGames = scoreboard?.games?.sort((a, b) => {
        const aVal = a.created ? +new Date(a.created) : 0;
        const bVal = b.created ? +new Date(b.created) : 0;

        return bVal - aVal;
    }) || [];

    const {user} = useContext(AuthContext);

    const getScoreboard = async () => {
        const res = await sendGetRequest('/api/scoreboards/' + id);
        if (res.ok) {
            setScoreboard(res.body as Scoreboard);
        }
    }

    useEffect(() => {
        if (id) {
            getScoreboard()
        }

        return () => {
            setScoreboard(undefined)
        }
    }, [id]);

    useEffect(() => {
        if (scoreboard) {
            setScoreboardData()
        }
    }, [scoreboard])

    const onAddGameSuccess = async () => {
        await getScoreboard();
        setIsModalOpen(false);
    }

    if (!user || !scoreboard) {
        return <Loading />
    }

    const getGameColumns = () => {
        const users = scoreboard.users?.map((user) => {
                return (
                    {
                        key: user.userHandle,
                        title: user.userHandle,
                    }
                )
            }
        ) || [];

        return [
            {key: "winners", title: "Winner"},
            ...users
        ]
    }

    const getStatsColumns = () => {
        return [
            {key: "userHandle", title: "Player"},
            {key: "victories", title: "Victories"},
            {key: "topScore", title: isHighScoreType() ? "High Score" : "Low Score"},
            {key: "average", title: "Average Score"},
        ]
    }

    const setScoreboardData = () => {
        if (!sortedGames.length) return [];

        const totalTally: any = {};
        let reigningChamps = "";

        let current = 0;
        let stillCounting = true;
        let longest = 0;
        let longestCounter = 0;
        let lastWinner: string;
        const modifiedRows = sortedGames.map((game, idx) => {
            const row: {[key: string]: any} = {
                id: game.id,
                created: game.created,
                scores: game.scores
            }
            let winners: Score[] = [];

            game.scores.forEach((score) => {
                if (!totalTally[score.user.userHandle]) {
                    totalTally[score.user.userHandle] = {
                        total: score.score || 0,
                        topScore: score.score || 0
                    }
                } else {
                    totalTally[score.user.userHandle]["total"] += score.score || 0;
                    if (isHighScoreType()) {
                        if (score.score && score.score > totalTally[score.user.userHandle]["topScore"]) {
                            totalTally[score.user.userHandle]["topScore"] = score.score
                        }
                    } else {
                        if (score.score && score.score < totalTally[score.user.userHandle]["topScore"]) {
                            totalTally[score.user.userHandle]["topScore"] = score.score
                        }
                    }
                }

                if (!winners.length) {
                    winners = [score];
                } else if (score.score && winners[0].score && score.score > winners[0].score && isHighScoreType()) {
                    winners = [score]
                } else if (score.score && winners[0].score && score.score < winners[0].score && !isHighScoreType()) {
                    winners = [score]
                } else if (score.score === winners[0].score) {
                    winners.push(score);
                }

                row[score.user.userHandle] = score.score
            })


            row["winners"] = winners.map(it => {
                if (!totalTally[it.user.userHandle]["victories"]) {
                    totalTally[it.user.userHandle]["victories"] = 1
                } else {
                    totalTally[it.user.userHandle]["victories"] = totalTally[it.user.userHandle]["victories"] + 1
                }
                return it.user.userHandle
            }).join(", ")

            if (!reigningChamps) {
                reigningChamps = row["winners"]
            }

            if (!lastWinner || lastWinner === row['winners']) {
                longestCounter++;
            } else {
                longestCounter = 1;
            }

            if (longestCounter > longest) {
                longest = longestCounter;
            }

            lastWinner = row['winners'];

            if (stillCounting && reigningChamps === row['winners']) {
                current++;
            } else {
                stillCounting = false;
            }

            return row;
        });

        setLongestStreak(longest);
        setCurrentStreak(current);
        setRows(modifiedRows);

        Object.keys(totalTally).forEach((it) => {
            const num = ((totalTally[it]["total"] || 0) / sortedGames.length)
            totalTally[it]["average"] = Math.round((num + Number.EPSILON) * 100) / 100;
        })

        setScoreboardStats(totalTally);
        setChamp(reigningChamps);

        let highScore: {[key: string]: any} = {};

        Object.keys(totalTally).forEach(userHandle => {
            const userStats = totalTally[userHandle];
            if (!highScore.userHandle) {
                highScore = {
                    userHandle,
                    score: userStats.topScore
                }
            } else if (userStats.topScore > highScore.score && isHighScoreType()) {
                highScore = {
                    userHandle,
                    score: userStats.topScore
                }
            } else if (userStats.topScore < highScore.score && !isHighScoreType()) {
                highScore = {
                    userHandle,
                    score: userStats.topScore
                }
            }
        })

        setHighScore(highScore);
    }

    const getAggregatedRows = () => {
        return Object.keys(scoreboardStats).map((userHandle => {
            const userStats = scoreboardStats[userHandle]
            return {
                userHandle,
                victories: userStats.victories,
                topScore: userStats.topScore,
                average: userStats.average
            }
        })).sort ((a, b) => {
            return b.victories - a.victories;
        })
    }

    const getAggregatedStats = () => {
        return <CustomTable onRowClick={(it) => console.log(it)} rows={getAggregatedRows()} columns={getStatsColumns()} />
    }


    const getGames = () => {
        if (!sortedGames.length) return (
            <>
                <Grid item xs={0} md={4} />
                <Grid className={styles.center} justifyContent={'center'} item xs={12} md={4}>
                    You have no games recorded. Add one now!
                </Grid>
                <Grid item xs={0} md={4} />
            </>
        );

        return (
            <Grid item xs={12}>
                <CustomTable onRowClick={(it) => setSelectedGame(it)} rows={rows} columns={getGameColumns()} />
            </Grid>
        )
    }

    return (
        <>
            <Container fixed className={styles.container}>
                <Grid item xs={12}>
                    <ArrowBackIcon onClick={() => navigate("/")} />
                </Grid>
                <Grid className={styles["shared-normal-font"]} container spacing={2}>
                    <Grid className={styles.center} justifyContent={'center'} item  xs={12}>
                        <span className={styles["shared-normal-font-big"]}>
                            {scoreboard.gameType?.name} - {scoreboard.name}
                        </span>
                    </Grid>

                    {
                        !!champ ? (
                            <Grid item md={4} xs={12}>
                                Reigning Champ:&nbsp;&nbsp;<span className={styles["shared-bold-font"]}>{champ}</span>
                            </Grid>
                        ) : (
                            <Grid item md={4} xs={12} />
                        )
                    }
                    {
                        !!highScore.score ? (
                            <Grid item md={4} xs={12}>
                                {isHighScoreType() ? "High Score: " : "Low Score: "}&nbsp;
                                <span className={styles["shared-bold-font"]}>
                                    {highScore.userHandle} ({highScore.score})
                                </span>
                            </Grid>
                        ) : (
                            <Grid item md={4} xs={12} />
                        )
                    }

                    <Grid item md={4} xs={12} />

                    {
                        !!currentStreak ? (
                            <Grid item md={4} xs={12}>
                                Current Streak:&nbsp;&nbsp;<span className={styles["shared-bold-font"]}>{currentStreak} in a row</span>
                            </Grid>
                        ) : (
                            <Grid item md={4} xs={12} />
                        )
                    }

                    {
                        !!longestStreak ? (
                            <Grid item md={4} xs={12}>
                                Longest Streak:&nbsp;&nbsp;<span className={styles["shared-bold-font"]}>{longestStreak} in a row</span>
                            </Grid>
                        ) : (
                            <Grid item md={4} xs={12} />
                        )
                    }

                    <Grid className={styles.center} justifyContent={'center'} item md={4} xs={12}>
                        <Button
                            onClick={() => setIsModalOpen(true)}
                            variant={"contained"}
                            color={"success"}>
                            Add a game
                        </Button>
                    </Grid>

                    <Spacer space={12} />

                    {
                        !!sortedGames.length && (
                            <>
                                <Grid item xs={4} />
                                <Grid className={styles.center} justifyContent={'center'} item xs={4}>
                                    Player Stats
                                </Grid>
                                <Grid item xs={4} />

                                <Grid item xs={12}>
                                    {getAggregatedStats()}
                                </Grid>

                                <Spacer space={32} />

                                <Grid item xs={4} />
                                <Grid className={styles.center} justifyContent={'center'} item xs={4}>
                                    All Games
                                </Grid>
                                <Grid item xs={4} />
                            </>
                        )
                    }

                    {getGames()}
                </Grid>
            </Container>
            {
                isModalOpen && (
                    <Modal
                        open={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Box className={styles.formContainer}>
                            <CloseBar onClick={() => setIsModalOpen(false)} />
                            <AddGameToScoreboardForm scoreboard={scoreboard} onSuccess={onAddGameSuccess} />
                        </Box>
                    </Modal>
                )
            }
            {
                selectedGame && (
                    <Modal
                        open={!!selectedGame}
                        onClose={() => setSelectedGame(undefined)}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Box className={styles.formContainer}>
                            <CloseBar onClick={() => setSelectedGame(undefined)} />
                            <Grid className={styles["shared-normal-bold"]} container>
                                <Grid item xs={6}>
                                    Date
                                </Grid>
                                <Grid item xs={6}>
                                    {new Date(selectedGame.created).toLocaleString()}
                                </Grid>
                                <Spacer space={12} />
                                {selectedGame.scores?.map((score: Score) => {
                                    return (
                                        <Fragment key={`game-view-user-score-${selectedGame.id}-${score.user.userHandle}`}>
                                            <Grid item xs={6}>
                                                {score.user?.userHandle}
                                            </Grid>
                                            <Grid item xs={6}>
                                                {score.score}
                                            </Grid>
                                        </Fragment>
                                    )
                                })}
                            </Grid>
                        </Box>
                    </Modal>
                )
            }
        </>
    );
}

export default ScoreboardPage;
