import React, {useEffect, useState} from 'react';

import TextField from '@mui/material/TextField';
import {Box, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {Spacer} from "../../UI/Spacer/Spacer";
import Button from "@mui/material/Button";
import useApiClient from "../../../hooks/use-api-client";
import {Loading} from "../../UI/Loading/Loading";

type SendFriendRequestFormProps = {
    onSuccess: (userHandle: string) => void;
}

type SearchResult = {
    userHandle?: string;
}

const SendFriendRequestForm = ({onSuccess}: SendFriendRequestFormProps) => {
    const {sendPostRequest} = useApiClient();
    const [loading, setLoading] = useState(false);
    const [userHandles, setUserHandles] = useState<string[]>([]);
    const [query, setQuery] = useState('');
    const [selectedUser, setSelectedUser] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        let t: NodeJS.Timeout;
        if (query) {
            t = setTimeout(async () => {
                setLoading(true);
                const res = await sendPostRequest("/api/users/search", {search: query});
                if (res.ok) {
                    const handles = (res.body as SearchResult[])?.map((it) => it.userHandle) as string[];
                    setUserHandles(handles);
                    setIsOpen(!!handles?.length);
                } else {
                    setUserHandles([])
                }
                setLoading(false);
            }, 500)
        }

        return () => {
            if (t) clearTimeout(t);
        }
    }, [query]);

    const submit = async () => {
        if (selectedUser) {
            const res = await sendPostRequest("/api/users/friend-requests/",{userHandle: selectedUser});
            if (res.ok) {
                onSuccess(selectedUser);
            }
        }
    }

    return (
        <Box component="form">
            <div>
                <FormControl fullWidth>
                    <TextField
                        required
                        id="outlined-required"
                        label="Search"
                        value={query}
                        autoComplete={'off'}
                        onChange={(e) => setQuery(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </FormControl>
                <Spacer space={14} />
                {
                    loading ? (
                        <Loading/>
                    ) : (
                        <>
                            <FormControl fullWidth>
                                <InputLabel>Users</InputLabel>
                                <Select
                                    value={selectedUser}
                                    label="Users"
                                    onChange={(e) => {
                                        setSelectedUser(e.target.value);
                                        setIsOpen(false);
                                    }}
                                    open={isOpen}
                                >
                                    {
                                        userHandles.map(it => {
                                            return (
                                                <MenuItem key={`found-user-${it}`} value={it}>{it}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <Spacer space={14} />
                            <Spacer space={14} />
                            <FormControl fullWidth>
                                {
                                    loading ? <Loading /> : (
                                        <Button
                                            onClick={submit}
                                            variant={"contained"}
                                            color={"success"}
                                        >
                                            Send Request
                                        </Button>
                                    )
                                }
                            </FormControl>
                        </>
                    )
                }
            </div>
        </Box>
    );
}

export default SendFriendRequestForm;
