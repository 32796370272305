import * as React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';

import styles from "./App.module.scss";
import Settings from "./pages/Settings/Settings";
import PageNotFoundPage from "./pages/PageNotFoundPage/PageNotFoundPage";
import {Loading} from "./components/UI/Loading/Loading";
import {useEffect, useMemo, useState} from "react";
import {AuthContext} from "./hooks/use-auth-state";
import {createTheme, ThemeProvider} from "@mui/material";
import {User} from "./models/User";
import useApiClient from "./hooks/use-api-client";
import ResponsiveAppBar from "./components/ResponsiveAppBar/ResponsiveAppBar";
import MyScoreboards from "./pages/MyScoreboards/MyScoreboards";
import {GameType} from "./models/GameType/GameType";
import SignUp from "./pages/SignUp/SignUp";
import ScoreboardPage from "./pages/Scoreboard/Scoreboard";
import {FriendRequest} from "./models/FriendRequest";

const outerTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const App = () => {
    const {
        isLoading,
        isAuthenticated,
        error,
    } = useAuth0();
    const {sendGetRequest} = useApiClient();
    const [apiUser, setApiUser] = useState<any>();
    const [gameTypes, setGameTypes] = useState<GameType[]>([])
    const [isFetchingUser, setIsFetchingUser] = useState(false);
    const [needsToRegister, setNeedsToRegister] = useState(false);
    const [friendRequests, setFriendRequests] = useState<FriendRequest[]>([]);

    const getUser = async () => {
        setIsFetchingUser(true);
        const res = await sendGetRequest('/api/users/me');
        if (res.ok) {
            setNeedsToRegister(false);
            setApiUser(res.body as User);
        } else {
            setNeedsToRegister(true);
        }
        setIsFetchingUser(false);
    }

    const getFriendRequests = async () => {
        const res = await sendGetRequest("/api/users/friend-requests/received");
        if (res.ok) {
            setFriendRequests(res.body as FriendRequest[]);
        }
    }

    const getGameTypes = async () => {
        setIsFetchingUser(true);
        const res = await sendGetRequest('/api/gametypes/');
        if (res.ok) {
            setGameTypes(res.body as GameType[]);
        }
        setIsFetchingUser(false);
    }

    useEffect(() => {
        if (isAuthenticated) {
            getUser();
            getGameTypes();
            getFriendRequests();
        }
    }, [isAuthenticated]);

    const value = useMemo(
        () => {
            return { user: apiUser, setUser: setApiUser, getUser, getGameTypes, gameTypes, friendRequests, getFriendRequests }
        },
        [apiUser, gameTypes, friendRequests]
    );

    if (error) {
        return <div>Oops... {error.message}</div>;
    }

    const getContent = () => {
        if (isLoading || isFetchingUser) {
            return <Loading />
        } else if (needsToRegister) {
            return (
                <div>
                    <SignUp />
                </div>
            );
        } else if (isAuthenticated) {
            return (
                <div>
                    <Routes>
                        <Route path="/" element={<MyScoreboards />} />
                        <Route path="/profile" element={<Settings />} />
                        <Route path="/my-scoreboards/:id" element={<ScoreboardPage />} />
                        <Route element={<PageNotFoundPage />} />
                    </Routes>
                </div>
            );
        } else {
            return null;
        }
    }

    return (
        <AuthContext.Provider value={value}>
            <ThemeProvider theme={outerTheme}>
                <div className={styles.App}>
                    <Router >
                        <ResponsiveAppBar />
                        {getContent()}
                    </Router>
                </div>
            </ThemeProvider>
        </AuthContext.Provider>
    );
}

export default App;
