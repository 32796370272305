import React, {useState} from 'react';

import TextField from '@mui/material/TextField';
import {Box, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {CreateGameType, ScoreType} from "../../../models/GameType/CreateGameType";
import {Spacer} from "../../UI/Spacer/Spacer";
import Button from "@mui/material/Button";
import useApiClient from "../../../hooks/use-api-client";
import {GameType} from "../../../models/GameType/GameType";
import {Loading} from "../../UI/Loading/Loading";

type CreateGameTypeFormProps = {
    onSuccess: (gameType: GameType) => void;
}

const CreateGameTypeForm = ({onSuccess}: CreateGameTypeFormProps) => {
    const {sendPostRequest} = useApiClient();
    const [name, setName] = useState("");
    const [selectedScoreType, setSelectedScoreType] = useState(ScoreType.HigherScoreWins);
    const [loading, setLoading] = useState(false);

    const submit = async () => {
        setLoading(true);
        const gameType: CreateGameType = {
            name,
            scoreType: selectedScoreType
        }

        const res = await sendPostRequest("/api/gametypes/", gameType);
        setLoading(false);

        if (res.ok) {
            onSuccess(res.body as GameType);
        }
    }

    return (
        <Box component="form">
            <div>
                <FormControl fullWidth>
                    <TextField
                        required
                        id="outlined-required"
                        label="Name"
                        autoComplete={'off'}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </FormControl>
                <Spacer space={14} />
                <FormControl fullWidth>
                    <InputLabel>Scoring type</InputLabel>
                    <Select
                        value={selectedScoreType}
                        label="Scoring type"
                        onChange={(e) => setSelectedScoreType(e.target.value as ScoreType)}
                    >
                        <MenuItem value={ScoreType.HigherScoreWins}>Higher score wins</MenuItem>
                        <MenuItem value={ScoreType.LowerScoreWins}>Lower score wins (like golf)</MenuItem>
                    </Select>
                </FormControl>
                <Spacer space={14} />
                <FormControl fullWidth>
                    {
                        loading ? <Loading /> : (
                            <Button
                                onClick={submit}
                                variant={"contained"}
                                color={"success"}
                            >
                                Submit
                            </Button>
                        )
                    }
                </FormControl>
            </div>
        </Box>
    );
}

export default CreateGameTypeForm;
