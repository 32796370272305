import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    '&:hover': {
        background: 'rgba(255,255,255,.25)'
    },

    '&': {
        cursor: 'pointer'
    }
}));

export type TableProps = {
    rows: any[],
    columns: {
        key: string,
        title: string,
        type?: string
    }[],
    onRowClick?: (data: any) => void
}

const CustomTable = (props: TableProps) => {
    const {
        rows,
        columns,
        onRowClick
    } = props;

    const getColumnHeaders = () => {
        return columns.map((col, idx) => {
            return (
                <StyledTableCell key={`table-col-${idx}-${col.key}`}>{col.title}</StyledTableCell>
            )
        })
    }

    const onRowClicked = (data: any) => {
        onRowClick && onRowClick(data);
    }

    const getRowData = (row: any, key: string) => {
        return (
            <StyledTableRow onClick={() => onRowClicked(row)} key={`table-row-${key}`}>
                {
                    columns.map((col, idx) => {
                        let val;
                        if (col.key.includes(".")) {
                            const keys = col.key.split(".")
                            val = row[keys[0]][keys[1]];
                        } else {
                            val = row[col.key];
                        }
                        if (col.type === "date") {
                            val = new Date(val).toLocaleString()
                        }
                        if (idx === 0) {
                            return (
                                <StyledTableCell
                                    key={`val-${key}-${idx}`}
                                    component="th"
                                    scope="row"
                                >
                                    {val}
                                </StyledTableCell>
                            )
                        } else {
                            return (
                                <StyledTableCell key={`val-${key}-${idx}`} align="left">
                                    {val}
                                </StyledTableCell>
                            )
                        }
                    })
                }
            </StyledTableRow>
        )
    }

    return (
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        {getColumnHeaders()}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, idx) => (
                        getRowData(row, `table-row-${idx}`)
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default CustomTable;
