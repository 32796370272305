import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import {useContext} from "react";
import {AuthContext} from "../../hooks/use-auth-state";
import { useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import {useAuth0} from "@auth0/auth0-react";
import {IconButton, Menu, MenuItem, Typography} from "@mui/material";

const pages = [{title: "My Scoreboards", href: "/"}, {title: "Profile", href: "/profile"}];

const ResponsiveAppBar = () => {
    const {
        isAuthenticated,
        loginWithRedirect,
        logout,
        isLoading
    } = useAuth0();
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate();
    const {user, friendRequests} = useContext(AuthContext);

    const profilePage = pages.find(it => it.title === "Profile");
    if (profilePage && friendRequests.length) profilePage.title = `Profile (${friendRequests.length})`

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {!isLoading && (
                        <>
                            {
                                isAuthenticated && (
                                    <>
                                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                            {pages.map((page) => (
                                                <Button
                                                    key={page.title}
                                                    onClick={() => navigate(page.href, { replace: true })}
                                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                                >
                                                    {page.title}
                                                </Button>
                                            ))}
                                            <Button
                                                onClick={() => logout({
                                                    returnTo: process.env.REACT_APP_LOGOUT_REDIRECT
                                                })}
                                                sx={{ my: 2, color: 'white', display: 'inline-block' }}
                                            >
                                                Sign out
                                            </Button>
                                        </Box>

                                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                            <IconButton
                                                size="large"
                                                aria-label="account of current user"
                                                aria-controls="menu-appbar"
                                                aria-haspopup="true"
                                                onClick={handleOpenNavMenu}
                                                color="inherit"
                                            >
                                                <MenuIcon />
                                            </IconButton>
                                            <Menu
                                                id="menu-appbar"
                                                anchorEl={anchorElNav}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                                keepMounted
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                                open={Boolean(anchorElNav)}
                                                onClose={handleCloseNavMenu}
                                                sx={{
                                                    display: { xs: 'block', md: 'none' },
                                                }}
                                            >
                                                {pages.map((page) => (
                                                    <MenuItem key={page.title} onClick={() => navigate(page.href, { replace: true })}>
                                                        <Typography textAlign="center">{page.title}</Typography>
                                                    </MenuItem>
                                                ))}
                                                <MenuItem onClick={() => logout({
                                                    returnTo: process.env.REACT_APP_LOGOUT_REDIRECT
                                                })}>
                                                    <Typography textAlign="center">Sign out</Typography>
                                                </MenuItem>
                                            </Menu>
                                        </Box>
                                    </>
                                )
                            }

                            <Box sx={{ flexGrow: 0 }}>
                                <Tooltip title={user?.userHandle || ""}>
                                    <>
                                        {
                                            user?.profilePicUrl ?
                                                (
                                                    <img
                                                        alt={user.userHandle}
                                                        style={{borderRadius: "50%"}}
                                                        height={'50'}
                                                        width={'50'}
                                                        src={user.profilePicUrl}
                                                    />
                                                ) : (
                                                    <span>{user?.userHandle || ""}</span>
                                                )
                                        }
                                        {
                                            !isAuthenticated && (
                                                <Button
                                                    onClick={loginWithRedirect}
                                                    sx={{ my: 2, color: 'white', display: 'inline-block' }}
                                                >
                                                    Sign in
                                                </Button>
                                            )
                                        }
                                    </>
                                </Tooltip>
                            </Box>
                        </>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default ResponsiveAppBar;
