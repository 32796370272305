import styles from "./CloseBar.module.scss";
import * as React from "react";

import CloseIcon from '@mui/icons-material/Close';

type CloseBarProps = {
    onClick: () => void;
}

export const CloseBar = ({onClick}: CloseBarProps) => {
    return (
        <div className={styles["take-space"]}>
            <div className={styles["top-right"]} onClick={onClick} >
                <CloseIcon />
            </div>
        </div>
    );
}
