import * as React from "react";
import sharedStyles from "../../assets/styles/XxxSharedStyles.module.scss";
import {Link} from "react-router-dom";

const PageNotFoundPage = () => {
    return (
        <div className={sharedStyles.page}>
            <div className={sharedStyles.pageTitle}>Page Not Found</div>
            <div className={sharedStyles.mainCard}>
                <p>
                    The requested page at this address is not found.{" "}
                    <Link to="/">Home Page</Link>
                </p>
            </div>
        </div>
    );
}

export default PageNotFoundPage;
